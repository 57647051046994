export enum BlockDragAndDropFormats {
  BlockDrag = 'blockDrag',
  BlockReorder = 'blockReorder',
  ImageDrag = 'imageData'
}

export enum BlockKeys {
  CreatedAt = 'createdAt',
  Data = 'data',
  DeletedAt = 'deletedAt',
  Key = 'key',
  Locale = 'locale',
  PageKey = 'pageKey',
  ParentKey = 'parentKey',
  Position = 'position',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum BlockDataColorKeys {
  Data = 'data',
  Description = 'description',
  Key = 'key',
  Name = 'name'
}

export enum BlockDataColorDataKeys {
  B = 'b',
  C = 'c',
  G = 'g',
  Hex = 'hex',
  K = 'k',
  M = 'm',
  Pantone = 'pantone',
  PantoneUncoated = 'pantone_u',
  R = 'r',
  Y = 'y'
}

/**
 * Shared between Excel, PowerPoint, and Word
 */
export enum BlockDataDocKeys {
  CdnUrl = 'cdnUrl',
  Description = 'description',
  Height = 'height',
  Key = 'key',
  Name = 'name',
  Width = 'width'
}

export enum BlockDataHtmlKeys {
  Html = 'html'
}

export enum BlockDataImageKeys {
  CdnUrl = 'cdnUrl',
  Description = 'description',
  Height = 'height',
  Key = 'key',
  Name = 'name',
  ThumbnailUrl = 'thumbnailUrl',
  Width = 'width'
}

export enum BlockDataPdfKeys {
  CdnUrl = 'cdnUrl',
  Description = 'description',
  Height = 'height',
  Key = 'key',
  Look = 'look',
  Name = 'name',
  Width = 'width'
}

export enum BlockDataSectionKeys {
  Id = 'id',
  Name = 'name'
}

export enum BlockDataVideoKeys {
  CdnUrl = 'cdnUrl',
  Description = 'description',
  Height = 'height',
  Key = 'key',
  MimeType = 'mimeType',
  Name = 'name',
  Width = 'width'
}

export enum BlockTypes {
  Color = 'color', // asset
  Excel = 'excel',
  Html = 'html',
  Image = 'image', // asset
  Pdf = 'pdf',
  PowerPoint = 'powerpoint',
  Section = 'section',
  Text = 'text',
  Video = 'video',
  Word = 'word'
}

export enum PdfLooks {
  List = 'list',
  Pagination = 'pagination'
}

export enum VideoMimeTypes {
  Mp4 = 'video/mp4'
}
