import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

import { Block } from '@typings/block';
import { Page } from '@typings/page';

interface PagesContextState {
  draggingPage: Page | null;
  draggingSection: Block | null;
  editingPage: Page | null;
  editingSections: string[];
  movingPage: Page | null;
  setDraggingPage: Dispatch<SetStateAction<Page | null>>;
  setDraggingSection: Dispatch<SetStateAction<Block | null>>;
  setEditingPage: Dispatch<SetStateAction<Page | null>>;
  setEditingSections: Dispatch<SetStateAction<string[]>>;
  setMovingPage: Dispatch<SetStateAction<Page | null>>;
}

export const PagesContext = createContext<PagesContextState>({
  draggingPage: null,
  draggingSection: null,
  editingPage: null,
  editingSections: [],
  movingPage: null,
  setDraggingPage: () => {},
  setDraggingSection: () => {},
  setEditingPage: () => {},
  setEditingSections: () => {},
  setMovingPage: () => {}
});

interface PagesContextProps {
  children: ReactNode;
}

export const PagesProvider: FunctionComponent<PagesContextProps> = ({ children }) => {
  const [draggingPage, setDraggingPage] = useState<Page | null>(null);
  const [draggingSection, setDraggingSection] = useState<Block | null>(null);
  const [editingPage, setEditingPage] = useState<Page | null>(null);
  const [editingSections, setEditingSections] = useState<string[]>([]);
  const [movingPage, setMovingPage] = useState<Page | null>(null);

  return (
    <PagesContext.Provider
      value={{
        draggingPage,
        draggingSection,
        editingPage,
        editingSections,
        movingPage,
        setDraggingPage,
        setDraggingSection,
        setEditingPage,
        setEditingSections,
        setMovingPage
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};
