import { deepEqual } from 'fast-equals';
import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { toast } from 'react-hot-toast';

import { BlockTypes } from '@enums/blocks';
import { ApiDatum } from '@typings/api';
import { Block } from '@typings/block';
import { createBlockPatchPromise, getPageBlocks } from '@utilities/block';

import logger from '@utilities/logger';

import { BlockContext } from './blocks';
import { BrandguideContext } from './brandguide';
import { PageContext } from './page';

interface PublishContextState {
  autoFocusKey: string;
  publish: boolean;
  publishing: boolean;
  setAutoFocusKey: Dispatch<SetStateAction<string>>;
  setPublish: Dispatch<SetStateAction<boolean>>;
}

export const PublishContext = createContext<PublishContextState>({
  autoFocusKey: '',
  publish: false,
  publishing: false,
  setAutoFocusKey: () => {},
  setPublish: () => {}
});

interface PublishContextProps {
  children: ReactNode;
}

export const PublishProvider: FunctionComponent<PublishContextProps> = ({ children }) => {
  const [autoFocusKey, setAutoFocusKey] = useState('');
  const [publish, setPublish] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const { blocks, initialBlocks, setBlocks, setInitialBlocks } = useContext(BlockContext);
  const { brandguide } = useContext(BrandguideContext);
  const { pages } = useContext(PageContext);

  const blockDeletePromise = (block: Block) => {
    return fetch(`/api/blocks/${block.key}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-brandguide-key': brandguide?.key || ''
      },
      method: 'DELETE'
    });
  };

  const blockPostPromise = (block: Block) => {
    return fetch(`/api/blocks`, {
      body: JSON.stringify({
        data: {
          attributes: block
        }
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-brandguide-key': brandguide?.key || ''
      },
      method: 'POST'
    });
  };

  const createBlockPromises = () => {
    const promises: Promise<Response>[] = [];

    /**
     * We loop over pages because the `position` for each block
     * is relative to the page it belongs to
     * (not all blocks in the system for the Brandguide)
     */
    pages.forEach((page) => {
      const pageBlocks = getPageBlocks(blocks, page.key);
      if (pageBlocks.length > 0) {
        pageBlocks.forEach((block, i) => {
          if (block.deletedAt) {
            promises.push(blockDeletePromise(block));
          } else if (block.updatedAt) {
            const initialBlock = initialBlocks.find((b) => b.key === block.key);
            if (initialBlock && !deepEqual(initialBlock, block)) {
              promises.push(
                createBlockPatchPromise({ ...block, position: i }, brandguide?.key || '')
              );
            }
          } else {
            promises.push(blockPostPromise({ ...block, position: i }));
          }
        });
      }
    });

    return promises;
  };

  const handlePublish = async () => {
    const promises = createBlockPromises();

    if (promises.length > 0) {
      const loading = toast.loading('Saving...');
      setPublishing(true);

      try {
        const responses = await Promise.all(promises);
        const notOk = responses.find((response) => !response.ok);

        if (notOk) {
          const contentTooLarge = responses.find((response) => response.status === 413);
          if (contentTooLarge) {
            logger.error(new Error('413 Content Too Large'));

            toast.dismiss(loading);
            toast.error(
              'You have reached the limit of content allowed in a block. Please remove content or add another block.'
            );
          } else {
            throw new Error('Error saving blocks');
          }
        } else {
          const saved: ApiDatum<Block>[] = [];

          for (const response of responses) {
            // don't response.json for 204 deleted
            if (response.status === 200) {
              saved.push((await response.json()) as ApiDatum<Block>);
            }
          }

          const published = blocks
            .filter((block) => block.deletedAt === null || block.deletedAt === undefined)
            .map((b) => {
              const block = saved.find((s) => b.key === s.data.id);
              return block ? { ...b, ...block.data.attributes } : { ...b };
            });

          // used for autofocusing tinymce right after save
          const blockToAutoFocus = blocks.find(
            (block) => !block.updatedAt && block.type === BlockTypes.Text
          );
          if (blockToAutoFocus) {
            setAutoFocusKey(blockToAutoFocus.key);
          }

          setBlocks(published);
          setInitialBlocks(published);

          toast.dismiss(loading);
          toast.success('Saved!');
        }
      } catch (error) {
        logger.error(error);

        toast.dismiss(loading);
        toast.error('Error saving.');
      } finally {
        setPublishing(false);
        setPublish(false);
      }
    } else {
      setPublish(false);
    }
  };

  useEffect(() => {
    if (publish) {
      handlePublish();
    }
  }, [publish]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PublishContext.Provider
      value={{ autoFocusKey, publish, publishing, setAutoFocusKey, setPublish }}
    >
      {children}
    </PublishContext.Provider>
  );
};
