import { NextApiRequest, NextApiResponse } from 'next';

import { PermissionLevels } from '@enums/permission-levels';
import { ApiDatum } from '@typings/api';
import { PermissionLevel } from '@typings/permission-level';
import logger from '@utilities/logger';
import { getRefreshedToken } from '@utilities/oauth2';

const getHeader = (header: string | string[] | undefined): string | undefined => {
  return Array.isArray(header) ? header[0] : header;
};

/**
 * Get whether the user is an admin.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsAdmin = (level?: PermissionLevel): boolean => {
  if (!level) return false;
  return level === PermissionLevels.Admin || level === PermissionLevels.Owner;
};

/**
 * Get whether the user is anonymous.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsAnonymous = (level?: PermissionLevel): boolean => {
  if (!level) return true;
  return level === PermissionLevels.Empty || level === PermissionLevels.None;
};

/**
 * Get whether the user is a collaborator.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsCollaborator = (level?: PermissionLevel): boolean => {
  if (!level) return false;
  return level === PermissionLevels.Collaborator;
};

/**
 * Get whether the user is a guest.
 * @param level PermissionLevel | undefined
 * @returns boolean
 */
export const getIsGuest = (level?: PermissionLevel): boolean => {
  if (!level) return false;
  return level === PermissionLevels.Guest;
};

/**
 * A promise to GET user permission level from boulder.
 * Server-side (Node) only.
 *
 * @param req NextApiRequest
 * @param res NextApiRequest
 * @returns Promise<PermissionLevels>
 */
export const fetchPermissionLevel = async (
  req: NextApiRequest,
  res: NextApiResponse
): Promise<PermissionLevels> => {
  let permissionLevel: PermissionLevels = PermissionLevels.Empty;

  try {
    const { headers } = req;
    const brandguideKey = getHeader(headers['x-brandguide-key']);

    if (!brandguideKey) {
      return permissionLevel;
    }

    const { authorization } = headers;
    const { accessToken } = await getRefreshedToken(req, res);

    if (!authorization && !accessToken) {
      return permissionLevel;
    }

    const { origin } = headers;

    const response = await fetch(`${origin}/api/v4/brandguides/${brandguideKey}/permission_level`, {
      headers: {
        Authorization: authorization || `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'GET'
    });

    if (response.ok) {
      const json = (await response.json()) as ApiDatum<PermissionLevel>;
      permissionLevel = json.data.id as PermissionLevels;
      return permissionLevel;
    } else {
      throw new Error('Error fetching permission level.');
    }
  } catch (error) {
    logger.error(error);
    return permissionLevel;
  }
};
