import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { toast } from 'react-hot-toast';

import { BlockTypes } from '@enums/blocks';
import { ApiDatum } from '@typings/api';
import { AssetDataColor } from '@typings/asset';
import { Block, BlockColor } from '@typings/block';
import {
  createBlockPatchPromise,
  getBlockDataIsColor,
  getHasColorBlockChanged
} from '@utilities/block';

import logger from '@utilities/logger';

import { AssetsContext } from './assets';
import { BlockContext } from './blocks';
import { BrandguideContext } from './brandguide';

interface SyncContextState {
  setSync: Dispatch<SetStateAction<boolean>>;
  sync: boolean;
  syncing: boolean;
}

export const SyncContext = createContext<SyncContextState>({
  sync: false,
  syncing: false,
  setSync: () => {}
});

interface SyncContextProps {
  children: ReactNode;
}

export const SyncProvider: FunctionComponent<SyncContextProps> = ({ children }) => {
  const [sync, setSync] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const { colorAssets, errorColors, fetchColors, loadingColors } = useContext(AssetsContext);
  const { blocks, setBlocks, setInitialBlocks } = useContext(BlockContext);
  const { brandguide } = useContext(BrandguideContext);

  const resetSync = () => {
    setSync(false);
    setSyncing(false);
  };

  const createBlockPromises = () => {
    const promises: Promise<Response>[] = [];

    if (colorAssets.length > 0) {
      const colorBlocks = blocks.filter(
        (block) => block.type === BlockTypes.Color && getBlockDataIsColor(block.data)
      );

      colorBlocks.forEach((block) => {
        const data = block.data as BlockColor;
        const asset = colorAssets.find((a) => a.id === data.key);

        if (asset) {
          const { data: assetData } = asset.attributes;
          const hasColorBlockChanged = getHasColorBlockChanged(asset.attributes, block);

          if (hasColorBlockChanged) {
            const { description: assetDescription, name: assetName } = asset.attributes;

            const blockColor: BlockColor = {
              ...data,
              data: assetData as AssetDataColor,
              description: assetDescription || '',
              name: assetName
            };

            promises.push(
              createBlockPatchPromise(
                {
                  ...block,
                  data: {
                    ...blockColor
                  }
                },
                brandguide?.key || ''
              )
            );
          }
        }
        // else asset may be deleted (do nothing)
      });
    }

    return promises;
  };

  const handleSync = async () => {
    const promises = createBlockPromises();

    if (promises.length > 0) {
      const loading = toast.loading('Syncing...');
      setSyncing(true);

      try {
        const responses = await Promise.all(promises);
        const notOk = responses.find((response) => !response.ok);

        if (notOk) {
          throw new Error('Error syncing blocks');
        } else {
          const saved: ApiDatum<Block>[] = [];

          for (const response of responses) {
            saved.push((await response.json()) as ApiDatum<Block>);
          }

          const synced = blocks.map((b) => {
            const block = saved.find((s) => b.key === s.data.id);
            return block ? { ...b, ...block.data.attributes } : { ...b };
          });

          setBlocks(synced);
          setInitialBlocks(synced);

          toast.dismiss(loading);
          toast.success('Synced!');
        }
      } catch (error) {
        logger.error(error);

        toast.dismiss(loading);
        toast.error('Error syncing.');
      } finally {
        resetSync();
      }
    } else {
      toast.success('Synced!');

      setTimeout(() => {
        resetSync();
      }, 2000);
    }
  };

  useEffect(() => {
    if (sync) {
      fetchColors();
    }
  }, [sync]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorColors && sync) {
      resetSync();
      toast.error('Error syncing.');
    }
  }, [errorColors]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadingColors && sync) {
      setSyncing(true);
    }
  }, [loadingColors]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (colorAssets && sync) {
      handleSync();
    }
  }, [colorAssets]); // eslint-disable-line react-hooks/exhaustive-deps

  return <SyncContext.Provider value={{ sync, syncing, setSync }}>{children}</SyncContext.Provider>;
};
