import { whitelabelPresenter, Whitelabel } from '@brandfolder/utilities';

import { WhitelabelScope } from '@typings/whitelabel';

export const brandguideWhitelabel = (whitelabel: WhitelabelScope): Whitelabel => {
  return whitelabelPresenter(whitelabel);
};

export const isGettyWhitelabel = (whitelabel: WhitelabelScope): boolean => {
  return whitelabel === WhitelabelScope.Getty;
};
