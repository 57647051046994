import { ApiDatum } from '@typings/api';
import { Attachment } from '@typings/attachment';
import logger from '@utilities/logger';

/**
 * Get a single attachment from boulder
 *
 * @param key string
 * @returns Promise<ApiDatum<Attachment> | null>
 */
export const getAttachment = async (key: string): Promise<ApiDatum<Attachment> | null> => {
  try {
    const response = await fetch(`/api/v4/attachments/${key}`);
    if (response.ok) {
      const json = (await response.json()) as ApiDatum<Attachment>;
      return json;
    } else {
      throw new Error('Error fetching attachment');
    }
  } catch (error) {
    logger.error(error);
    return null;
  }
};

/**
 * Get whether a URL is a Smart CDN URL.
 *
 * Sometimes an attachment DTO url is a Smart CDN URL (like when placing a resized image from Panel SDK)
 * and other times it is not (like when dragging and dropping an image from Panel SDK).
 *
 * Taken from boulder where we generate the Smart CDN URL for attachments:
 * https://github.com/brandfolder/boulder/blob/f93ddfe062c249b2471dfb266f03dee9fcb04bcc/app/concerns/smart_cdn_url.rb#L59
 *
 * @example getIsAttachmentUrlSmartCdn('https://cdn-staging.bfldr.com/abc123/at/def456/test.jpg?auto=webp') => true
 * @param url string
 * @returns boolean
 */
export const getIsAttachmentUrlSmartCdn = (url: string): boolean => {
  return new URL(url).pathname.split('/').slice(1)[1] === 'at';
};
