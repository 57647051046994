import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

import { PublicRuntimeConfig } from '@typings/config';

interface ConfigContextState {
  publicRuntimeConfig: PublicRuntimeConfig;
  setPublicRuntimeConfig: Dispatch<SetStateAction<PublicRuntimeConfig>>;
}

const defaultPublicRuntimeConfig: PublicRuntimeConfig = {
  BRANDFOLDER_BOULDER_URL: '',
  DD_CLIENT_TOKEN: '',
  ENVIRONMENT: '',
  GIT_VERSION: '',
  GETTY_BOULDER_URL: '',
  GETTY_BRANDGUIDE_HOST: '',
  NEXTAUTH_URL: ''
};

export const ConfigContext = createContext<ConfigContextState>({
  publicRuntimeConfig: defaultPublicRuntimeConfig,
  setPublicRuntimeConfig: () => {}
});

interface ConfigContextProps {
  children: ReactNode;
}

export const ConfigProvider: FunctionComponent<ConfigContextProps> = ({ children }) => {
  const [publicRuntimeConfig, setPublicRuntimeConfig] = useState<PublicRuntimeConfig>(
    defaultPublicRuntimeConfig
  );

  return (
    <ConfigContext.Provider value={{ publicRuntimeConfig, setPublicRuntimeConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};
