/**
 * Get the first param if params is an array of strings,
 * or just return the string is it's not an array.
 *
 * @example getQueryParam('abc123') => abc123
 * @example getQueryParam(['def456', 'ghi789']) => def456
 * @param param string | string[] | undefined
 * @returns string | undefined
 */
export const getFirstQueryParam = <T extends string>(
  param?: string | string[] | undefined
): T | undefined => {
  return Array.isArray(param) && param[0]
    ? (param[0] as T)
    : Array.isArray(param)
    ? undefined
    : (param as T);
};
