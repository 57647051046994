/**
 * boulder db/structure.sql public.permission_level
 */
export enum PermissionLevels {
  Admin = 'admin',
  Collaborator = 'collaborator',
  Empty = '',
  Guest = 'guest',
  None = 'none',
  Owner = 'owner'
}
