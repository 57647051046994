import { BrandfolderGlobalReset } from '@brandfolder/react';
import '@brandfolder/react/lib/esm/index.css';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import 'video.js/dist/video-js.css';

import { AsideProvider } from '@context/aside';
import { AssetsProvider } from '@context/assets';
import { BlockProvider } from '@context/blocks';
import { BrandguideProvider } from '@context/brandguide';
import { ConfigProvider } from '@context/config';
import { DragAndDropProvider } from '@context/drag-and-drop';
import { FeatureFlagProvider } from '@context/feature-flag';
import { LocaleProvider } from '@context/locale';
import { OAuthProvider } from '@context/oauth';
import { PageProvider } from '@context/page';
import { PagesProvider } from '@context/pages';
import { PermissionLevelProvider } from '@context/permission-level';
import { PreviewProvider } from '@context/preview';
import { PublishProvider } from '@context/publish';
import { SessionProvider } from '@context/session';
import { SyncProvider } from '@context/sync';
import { WhitelabelProvider } from '@context/whitelabel';

function BrandguideApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <BrandfolderGlobalReset />
      <FeatureFlagProvider>
        <OAuthProvider>
          <SessionProvider>
            <ConfigProvider>
              <BrandguideProvider>
                <WhitelabelProvider>
                  <AssetsProvider>
                    <PermissionLevelProvider>
                      <LocaleProvider>
                        <PageProvider>
                          <PagesProvider>
                            <AsideProvider>
                              <DragAndDropProvider>
                                <BlockProvider>
                                  <PreviewProvider>
                                    <PublishProvider>
                                      <SyncProvider>
                                        <Component {...pageProps} />
                                        <Script id="ketch">
                                          {`!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has("property")?e.get("property"):"brandfolder_com_core_app",n=document.createElement("script");n.type="text/javascript",n.src="https://global.ketchcdn.com/web/v2/config/smartsheet/".concat(o,"/boot.js"),n.defer=n.async=!0,document.getElementsByTagName("head")[0].appendChild(n)}();`}
                                        </Script>
                                      </SyncProvider>
                                    </PublishProvider>
                                  </PreviewProvider>
                                </BlockProvider>
                              </DragAndDropProvider>
                            </AsideProvider>
                          </PagesProvider>
                        </PageProvider>
                      </LocaleProvider>
                    </PermissionLevelProvider>
                  </AssetsProvider>
                </WhitelabelProvider>
              </BrandguideProvider>
            </ConfigProvider>
          </SessionProvider>
        </OAuthProvider>
      </FeatureFlagProvider>
    </>
  );
}

export default BrandguideApp;
