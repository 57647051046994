import { Brandfolder, Collection } from '@brandfolder/types';
import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

import { Brandguide } from '@typings/brandguide';

interface BrandguideContextState {
  brandfolder: Brandfolder | null;
  brandguide: Brandguide | null;
  collection: Collection | null;
  isCname: boolean;
  setBrandfolder: Dispatch<SetStateAction<Brandfolder | null>>;
  setBrandguide: Dispatch<SetStateAction<Brandguide | null>>;
  setCollection: Dispatch<SetStateAction<Collection | null>>;
  setIsCname: Dispatch<SetStateAction<boolean>>;
}

export const BrandguideContext = createContext<BrandguideContextState>({
  brandfolder: null,
  brandguide: null,
  collection: null,
  isCname: false,
  setBrandfolder: () => {},
  setBrandguide: () => {},
  setCollection: () => {},
  setIsCname: () => {}
});

interface BrandguideContextProps {
  children: ReactNode;
}

export const BrandguideProvider: FunctionComponent<BrandguideContextProps> = ({ children }) => {
  const [brandfolder, setBrandfolder] = useState<Brandfolder | null>(null);
  const [brandguide, setBrandguide] = useState<Brandguide | null>(null);
  const [collection, setCollection] = useState<Collection | null>(null);
  const [isCname, setIsCname] = useState(false);

  return (
    <BrandguideContext.Provider
      value={{
        brandfolder,
        brandguide,
        collection,
        isCname,
        setBrandfolder,
        setBrandguide,
        setCollection,
        setIsCname
      }}
    >
      {children}
    </BrandguideContext.Provider>
  );
};
