import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';

export interface AsideContextState {
  autoFocusId: string;
  setAutoFocusId: Dispatch<SetStateAction<string>>;
  setShowBlocks: Dispatch<SetStateAction<boolean>>;
  setShowCodeBlock: Dispatch<SetStateAction<boolean>>;
  setShowColor: Dispatch<SetStateAction<boolean>>;
  setShowColorSettings: Dispatch<SetStateAction<boolean>>;
  setShowImage: Dispatch<SetStateAction<boolean>>;
  setShowPages: Dispatch<SetStateAction<boolean>>;
  setShowPagesSettings: Dispatch<SetStateAction<boolean>>;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  setShowText: Dispatch<SetStateAction<boolean>>;
  showBlocks: boolean;
  showCodeBlock: boolean;
  showColor: boolean;
  showColorSettings: boolean;
  showImage: boolean;
  showPages: boolean;
  showPagesSettings: boolean;
  showSettings: boolean;
  showText: boolean;
}

export const AsideContext = createContext<AsideContextState>({
  autoFocusId: '',
  setAutoFocusId: () => {},
  setShowBlocks: () => {},
  setShowCodeBlock: () => {},
  setShowColor: () => {},
  setShowColorSettings: () => {},
  setShowImage: () => {},
  setShowPagesSettings: () => {},
  setShowPages: () => {},
  setShowSettings: () => {},
  setShowText: () => {},
  showBlocks: false,
  showCodeBlock: false,
  showColor: false,
  showColorSettings: false,
  showImage: false,
  showPagesSettings: false,
  showPages: false,
  showSettings: false,
  showText: false
});

interface AsideContextProps {
  children: ReactNode;
}

export const AsideProvider: FunctionComponent<AsideContextProps> = ({ children }) => {
  const [autoFocusId, setAutoFocusId] = useState('');
  const [showBlocks, setShowBlocks] = useState(false);
  const [showCodeBlock, setShowCodeBlock] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showColorSettings, setShowColorSettings] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showPagesSettings, setShowPagesSettings] = useState(false);
  const [showPages, setShowPages] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (showBlocks) {
      setShowPages(false);
      setShowSettings(false);
    } else {
      setShowCodeBlock(false);
      setShowColor(false);
      setShowImage(false);
      setShowText(false);
    }
  }, [showBlocks]);

  useEffect(() => {
    if (showCodeBlock) {
      setShowBlocks(true);

      setShowColor(false);
      setShowImage(false);
      setShowText(false);
    }
  }, [showCodeBlock]);

  useEffect(() => {
    if (showColor) {
      setShowBlocks(true);

      setShowCodeBlock(false);
      setShowImage(false);
      setShowText(false);
    }
  }, [showColor]);

  useEffect(() => {
    if (showImage) {
      setShowBlocks(true);

      setShowCodeBlock(false);
      setShowColor(false);
      setShowText(false);
    }
  }, [showImage]);

  useEffect(() => {
    if (showText) {
      setShowBlocks(true);

      setShowCodeBlock(false);
      setShowColor(false);
      setShowImage(false);
    }
  }, [showText]);

  useEffect(() => {
    if (showPages) {
      setShowBlocks(false);
      setShowSettings(false);
    }
  }, [showPages]);

  useEffect(() => {
    if (showSettings) {
      setShowBlocks(false);
      setShowPages(false);
    }
  }, [showSettings]);

  return (
    <AsideContext.Provider
      value={{
        autoFocusId,
        setAutoFocusId,
        setShowBlocks,
        setShowCodeBlock,
        setShowColor,
        setShowColorSettings,
        setShowImage,
        setShowPagesSettings,
        setShowPages,
        setShowSettings,
        setShowText,
        showBlocks,
        showCodeBlock,
        showColor,
        showColorSettings,
        showImage,
        showPagesSettings,
        showPages,
        showSettings,
        showText
      }}
    >
      {children}
    </AsideContext.Provider>
  );
};
