import { ApiData } from '@typings/api';
import { Asset, AssetBase, AssetCmyk, AssetDataColor, AssetRgb } from '@typings/asset';

export const mockAssetBase: AssetBase = {
  description: '',
  name: 'Purple Popsicle'
};

export const mockEmptyAssetBase: AssetBase = {
  description: '',
  name: ''
};

export const mockAssetCmyk: AssetCmyk = {
  c: '52',
  m: '40',
  y: '0',
  k: '21'
};

export const mockAssetRgb: AssetRgb = {
  r: '68',
  g: '98',
  b: '201'
};

export const mockAssetDataColor: AssetDataColor = {
  ...mockAssetCmyk,
  ...mockAssetRgb,
  hex: '4462C9',
  pantone: '2726',
  pantone_u: '2728'
};

export const mockEmptyAssetDataColor: AssetDataColor = {
  c: '',
  m: '',
  y: '',
  k: '',
  r: '',
  g: '',
  b: '',
  hex: '',
  pantone: '',
  pantone_u: ''
};

export const mockAssetColorPurplePopsicle: Asset = {
  ...mockAssetBase,
  approved: true,
  data: {
    ...mockAssetDataColor
  },
  thumbnail_url: ''
};

export const mockAssetColorBlack: Asset = {
  approved: true,
  data: {
    c: '0',
    m: '0',
    y: '0',
    k: '100',
    r: '0',
    g: '0',
    b: '0',
    hex: '000000',
    pantone: '',
    pantone_u: ''
  },
  description: '',
  name: 'Black',
  thumbnail_url: ''
};

export const mockAssetColorWhite: Asset = {
  approved: true,
  data: {
    c: '0',
    m: '0',
    y: '0',
    k: '0',
    r: '255',
    g: '255',
    b: '255',
    hex: 'ffffff',
    pantone: '',
    pantone_u: ''
  },
  description: '',
  name: 'White',
  thumbnail_url: ''
};

export const mockAssetsApiData: ApiData<Asset> = {
  data: [
    {
      attributes: { ...mockAssetColorPurplePopsicle },
      id: 'color123',
      type: 'color'
    },
    {
      attributes: { ...mockAssetColorBlack },
      id: 'color456',
      type: 'color'
    },
    {
      attributes: { ...mockAssetColorWhite },
      id: 'color789',
      type: 'color'
    }
  ]
};
