import { useRouter } from 'next/router';
import { createContext, FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { getFirstQueryParam } from '@utilities/query';

export interface FeatureFlagContextState {
  columns: boolean;
  dragDrop: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextState>({
  columns: false,
  dragDrop: false
});

interface FeatureFlagContextProps {
  children: ReactNode;
}

export const FeatureFlagProvider: FunctionComponent<FeatureFlagContextProps> = ({ children }) => {
  const { query } = useRouter();

  const [columns, setColumns] = useState(false);
  const [dragDrop, setDragDrop] = useState(false);

  useEffect(() => {
    setColumns(getFirstQueryParam(query.columns) === 'true');
    setDragDrop(getFirstQueryParam(query.dragDrop) === 'true');
  }, [query]);

  return (
    <FeatureFlagContext.Provider value={{ columns, dragDrop }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
