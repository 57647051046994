import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

import { SetStateCallback, useStateCallback } from '@hooks/use-state-callback';
import { Page } from '@typings/page';

interface PageContextState {
  page: Page | null;
  pages: Page[];
  setPage: SetStateCallback<Page | null>;
  setPages: Dispatch<SetStateAction<Page[]>>;
}

export const PageContext = createContext<PageContextState>({
  page: null,
  pages: [],
  setPage: () => {},
  setPages: () => {}
});

interface PageContextProps {
  children: ReactNode;
}

export const PageProvider: FunctionComponent<PageContextProps> = ({ children }) => {
  const [page, setPage] = useStateCallback<Page | null>(null);
  const [pages, setPages] = useState<Page[]>([]);

  return (
    <PageContext.Provider value={{ page, pages, setPage, setPages }}>
      {children}
    </PageContext.Provider>
  );
};
