import { Whitelabel } from '@brandfolder/utilities';

export enum WhitelabelScope {
  Brandfolder = '',
  Getty = 'getty'
}

export interface BrandguideWhitelabel extends Whitelabel {
  BOULDER_URL: string;
  BRANDGUIDE_URL: string;
}
