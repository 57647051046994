import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';

import { ApiDatum } from '@typings/api';
import { User } from '@typings/user';

interface OAuthContextState {
  authed: boolean;
  setAuthed: Dispatch<SetStateAction<boolean>>;
  user: User | null;
}

export const OAuthContext = createContext<OAuthContextState>({
  authed: false,
  setAuthed: () => {},
  user: null
});

interface OAuthContextProps {
  children: ReactNode;
}

export const OAuthProvider: FunctionComponent<OAuthContextProps> = ({ children }) => {
  const [authed, setAuthed] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const fetchUser = async () => {
    const response = await fetch('/api/v4/users/whoami');
    if (response.ok) {
      const json = (await response.json()) as ApiDatum<User, 'users'>;
      setUser({ ...json.data.attributes, key: json.data.id });
    } else {
      // do nothing, "User" will show in header
    }
  };

  useEffect(() => {
    if (authed && !user) {
      fetchUser();
    } else if (!authed && user) {
      setUser(null);
    }
  }, [authed]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OAuthContext.Provider
      value={{
        authed,
        setAuthed,
        user
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};
