export const knownCdns: string[] = [
  'bfldr.com',
  'brandfolder.com',
  'brandfolder.io',
  'gettyimages.com',
  'lvh.me'
];

export const preferredCdnHost = 'cdn.bfldr.com';

/**
 * Check if a CDN URL is a known one
 *
 * @param cdnUrl string
 * @returns boolean
 */
export const getIsKnownCdn = (cdnUrl: string): boolean => {
  return knownCdns.some((cdn) => cdnUrl.includes(cdn));
};

/**
 * Rewrite a customers CDN to Brandfolder. This handles customer CDN's found in
 * https://github.com/brandfolder/terraform/blob/master/infra/squads/asset/prod/fastly-smart-cdn-v2/terragrunt.hcl.
 *
 * @example cdn.customer.com => cdn.bfldr.com
 * @param cdnUrl string
 * @returns string
 */
export const rewriteCdnHost = (cdnUrl: string): string => {
  if (!cdnUrl || getIsKnownCdn(cdnUrl)) return cdnUrl;

  const url = new URL(cdnUrl);
  url.host = preferredCdnHost;
  return url.toString();
};
