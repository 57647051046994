import { IncomingMessage, ServerResponse } from 'node:http';

import { datadogRum } from '@datadog/browser-rum';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { DefaultOptions } from 'cookies-next/lib/types';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { NextRouter } from 'next/router';

import { OAuth2Cookies } from '@enums/oauth2';
import { BoulderJwt, OAuth2Jwt, OAuth2Payload } from '@typings/oauth2';
import logger from '@utilities/logger';
import { getIsLocalHost } from '@utilities/server';

/**
 * Next doesn't export this for some reason
 */
type NextIncomingMessage = IncomingMessage & {
  cookies: NextApiRequestCookies;
};

/**
 * Cookie options need to be the same when getting, setting, and deleting.
 * Server-side only (use in API routes and getServerSideProps).
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 *
 * @param domain string
 * @param req IncomingMessage | undefined
 * @param res ServerResponse | undefined
 * @returns OptionsType
 */
export const getOAuth2CookieOptions = (
  domain: string,
  req: NextIncomingMessage,
  res: ServerResponse
): DefaultOptions => {
  const isLocalHost = getIsLocalHost(domain);
  return {
    // domain is omitted intentionally (instead we use __Host- prefix, which is more secure)
    httpOnly: process.env.NODE_ENV === 'test' ? false : true,
    maxAge: 60 * 60 * 24 * 30, // 1 month
    path: '/',
    req,
    res,
    sameSite: 'lax', // lax lets us link from boulder to brandguide and maintain logged in state
    secure: isLocalHost ? false : true
  };
};

/**
 * Get cookie prefix (no prefix for http localhost)
 *
 * @param domain string
 * @param type OAuth2Cookies
 * @returns string
 */
export const getCookieName = (domain: string, type: OAuth2Cookies): string => {
  const isLocalHost = getIsLocalHost(domain);
  return isLocalHost ? type : `__Host-${type}`;
};

/**
 * Set access and refresh token to cookies
 *
 * @param accessToken string
 * @param refreshToken string
 * @param req IncomingMessage
 * @param res IncomingMessage
 * @returns void
 */
export const setTokenCookies = (
  accessToken: string,
  refreshToken: string,
  req: NextIncomingMessage,
  res: ServerResponse
): void => {
  const host = req.headers.host as string;
  const options = getOAuth2CookieOptions(host, req, res);

  setAccessTokenCookie(accessToken, req, res);
  setCookie(getCookieName(host, OAuth2Cookies.RefreshToken), refreshToken, options);
};

export const setAccessTokenCookie = (
  accessToken: string,
  req: NextIncomingMessage,
  res: ServerResponse
): void => {
  const host = req.headers.host as string;
  const options = getOAuth2CookieOptions(host, req, res);

  setCookie(getCookieName(host, OAuth2Cookies.AccessToken), accessToken, options);
};

/**
 * Delete access and refresh tokens from cookies
 *
 * @param req IncomingMessage
 * @param res ServerResponse
 * @returns void
 */
export const deleteTokenCookies = (req: NextIncomingMessage, res: ServerResponse) => {
  const host = req.headers.host as string;
  const options = getOAuth2CookieOptions(host, req, res);

  deleteCookie(getCookieName(host, OAuth2Cookies.AccessToken), options);
  deleteCookie(getCookieName(host, OAuth2Cookies.RefreshToken), options);
};

/**
 * Sign a user out by deleting cookies.
 * Server-side only (use in API routes and getServerSideProps).
 *
 * @param req IncomingMessage | undefined
 * @param res ServerResponse | undefined
 * @returns void
 */
export const signOut = (req: NextIncomingMessage, res: ServerResponse): void => {
  deleteTokenCookies(req, res);
  datadogRum.stopSessionReplayRecording();
};

export const redirectToSignOut = async (router: NextRouter) => {
  await router.push('/sign-out');
};

/**
 * Parse JWT token. Server-side (Node) only.
 * @param token string
 * @returns T | undefined
 */
export const parseJwt = <T>(token: string): T | undefined => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  } catch {
    return undefined;
  }
};

/**
 * Get if token is expired or will expire within 5 minutes.
 *
 * @param accessToken  string | undefined
 * @returns boolean
 */
export const getTokenWillExpireSoon = (accessToken?: string | undefined): boolean => {
  if (!accessToken) return true;

  const jwt = parseJwt<OAuth2Jwt>(accessToken);
  if (!jwt || !jwt.exp) return true;

  const fiveMinutesFromNow = Math.floor((Date.now() + 5 * 60_000) / 1000);
  if (jwt.exp < fiveMinutesFromNow) return true;

  return false;
};

/**
 * Get if token has expired.
 *
 * @param accessToken  string | undefined
 * @returns boolean
 */
export const getTokenHasExpired = (accessToken?: string | undefined): boolean => {
  if (!accessToken) return true;

  const jwt = parseJwt<OAuth2Jwt>(accessToken);
  if (!jwt || !jwt.exp) return true;

  if (jwt.exp < Math.floor(Date.now() / 1000)) return true;

  return false;
};

/**
 * Get if tokens are defined
 *
 * @param accessToken string | undefined
 * @param refreshToken string | undefined
 * @returns boolean
 */
export const getHasTokens = (
  accessToken?: string | undefined,
  refreshToken?: string | undefined
): boolean => {
  return !!accessToken && !!refreshToken;
};

interface GetTokenReturn {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  errored?: boolean;
  refreshed?: boolean;
}

/**
 * Get tokens from cookies.
 * Server-side only (use in API routes and getServerSideProps).
 *
 * @param req IncomingMessage
 * @param res ServerResponse
 * @returns GetTokenReturn
 */
export const getTokens = (req: NextIncomingMessage, res: ServerResponse): GetTokenReturn => {
  const host = req.headers.host as string;
  const options = getOAuth2CookieOptions(host, req, res);

  const accessToken = getCookie(getCookieName(host, OAuth2Cookies.AccessToken), options) as
    | string
    | undefined;

  const refreshToken = getCookie(getCookieName(host, OAuth2Cookies.RefreshToken), options) as
    | string
    | undefined;

  return {
    accessToken,
    refreshToken
  };
};

/**
 * Get tokens from cookies and refresh if about to expire within 5 minutes.
 * Server-side only (use in API routes and getServerSideProps).
 *
 * @param req IncomingMessage
 * @param res ServerResponse
 * @returns Promise<GetTokenReturn>
 */
export const getRefreshedToken = async (
  req: NextIncomingMessage,
  res: ServerResponse
): Promise<GetTokenReturn> => {
  const { accessToken, refreshToken } = getTokens(req, res);

  if (!getHasTokens(accessToken, refreshToken)) {
    return {
      accessToken: undefined,
      errored: false,
      refreshToken: undefined,
      refreshed: false
    };
  }

  if (getTokenWillExpireSoon(accessToken)) {
    try {
      // refresh token
      const response = await fetch(`${process.env.NEXTAUTH_URL}/api/auth/callback/hydra`, {
        body: JSON.stringify({ accessToken, refreshToken }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });

      if (response.ok) {
        const json = (await response.json()) as OAuth2Payload;
        return {
          accessToken: json.access_token,
          errored: false,
          refreshToken: json.refresh_token,
          refreshed: true
        };
      } else {
        logger.warn('No token in refresh response');

        return {
          accessToken: undefined,
          errored: true,
          refreshToken: undefined,
          refreshed: false
        };
      }
    } catch (error) {
      logger.error(error);

      return {
        accessToken: undefined,
        errored: true,
        refreshToken: undefined,
        refreshed: false
      };
    }
  } else {
    // tokens are still okay
  }

  return {
    accessToken,
    errored: false,
    refreshToken,
    refreshed: false
  };
};

/**
 * Get whether access token has user_key. Server-side (Node) only.
 *
 * @param accessToken string | undefined
 * @returns boolean
 */
export const getTokenHasUserKey = (accessToken?: string | undefined): boolean => {
  if (!accessToken) return false;

  const jwt = parseJwt<BoulderJwt | OAuth2Jwt>(accessToken);

  if (!jwt) return false;
  if ((jwt as OAuth2Jwt).ext?.user_key) return true;
  if ((jwt as BoulderJwt).user_key) return true;

  return false;
};

/**
 * Get access token from bearer string.
 *
 * @param bearer string
 * @returns string
 */
export const getAccessTokenFromBearer = (bearer: string): string => {
  return bearer.replace('Bearer ', '');
};

/**
 * Get whether access token is present and has user_key. Server-side (Node) only.
 *
 * @param req IncomingMessage
 * @param res ServerResponse
 * @returns boolean
 */
export const getHasAuth = (req: NextIncomingMessage, res: ServerResponse): boolean => {
  const { headers } = req;
  const { authorization } = headers;

  /**
   * Manually set authorization header via something like Postman
   */

  if (authorization) {
    const bearerToken = getAccessTokenFromBearer(authorization);
    return getTokenHasUserKey(bearerToken) && !getTokenHasExpired(bearerToken);
  }

  /**
   * Normal client-side flow via cookies
   */
  const { accessToken, refreshToken } = getTokens(req, res);
  if (getHasTokens(accessToken, refreshToken)) {
    return getTokenHasUserKey(accessToken) && !getTokenHasExpired(accessToken);
  }

  return false;
};

/**
 * Get whether access token has user_key. Returns an empty string if no user_key.
 * Server-side (Node) only.
 *
 * @param accessToken string | undefined
 * @returns string
 */
export const getTokenUserKey = (accessToken?: string | undefined): string => {
  if (!accessToken) return '';

  const jwt = parseJwt<BoulderJwt | OAuth2Jwt>(accessToken);

  if (!jwt) return '';
  if ((jwt as OAuth2Jwt).ext?.user_key) return (jwt as OAuth2Jwt).ext.user_key;
  if ((jwt as BoulderJwt).user_key) return (jwt as BoulderJwt).user_key;

  return '';
};
