import { sanitize } from '@brandfolder/utilities';

import { knownCdns } from '@utilities/cdn';

/**
 * These are current set up to allow Brandfolder video embeds.
 */
export const ADD_TAGS = ['iframe'];
export const ADD_ATTR = ['allowfullscreen', 'frameborder', 'sandbox', 'target'];
/**
 * NOTE: Don't use `allow-scripts`` and `allow-same-origin` together.
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#sect5
 */
export const ADD_IFRAME_SANDBOX = 'allow-scripts';
export const ALLOWED_IFRAME_DOMAINS = [...knownCdns, 'smartsheeet.com'];

/**
 * Sanitize HTML while allowing `iframe` tag with known `src` domains.
 *
 * @param value string
 * @returns string
 */
export const sanitizer = (value: string): string => {
  return sanitize(value, { ADD_ATTR, ADD_IFRAME_SANDBOX, ADD_TAGS, ALLOWED_IFRAME_DOMAINS });
};
