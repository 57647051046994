import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

import { Locales } from '@enums/locales';

interface LocaleContextState {
  locale: Locales;
  setLocale: Dispatch<SetStateAction<Locales>>;
}

export const LocaleContext = createContext<LocaleContextState>({
  locale: Locales.EnUS,
  setLocale: () => {}
});

interface LocaleContextProps {
  children: ReactNode;
}

export const LocaleProvider: FunctionComponent<LocaleContextProps> = ({ children }) => {
  const [locale, setLocale] = useState<Locales>(Locales.EnUS);

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};
