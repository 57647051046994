import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';

import { SetStateCallback, useStateCallback } from '@hooks/use-state-callback';
import { Block } from '@typings/block';
import { getPageBlocks, getSectionBlocks } from '@utilities/block';

import { PageContext } from './page';

interface BlockContextState {
  /**
   * All blocks returned from `pages/[...brandguide].tsx` `getServerSideProps`
   */
  blocks: Block[];
  initialBlocks: Block[];
  /**
   * Blocks filtered by the current page
   */
  pageBlocks: Block[];
  /**
   * All section blocks for all pages
   */
  sections: Block[];
  setBlocks: SetStateCallback<Block[]>;
  setInitialBlocks: Dispatch<SetStateAction<Block[]>>;
}

export const BlockContext = createContext<BlockContextState>({
  blocks: [],
  initialBlocks: [],
  pageBlocks: [],
  sections: [],
  setBlocks: () => {},
  setInitialBlocks: () => {}
});

interface BlockContextProps {
  children: ReactNode;
}

export const BlockProvider: FunctionComponent<BlockContextProps> = ({ children }) => {
  const { page } = useContext(PageContext);

  const [blocks, setBlocks] = useStateCallback<Block[]>([]);
  const [initialBlocks, setInitialBlocks] = useState<Block[]>([]);

  const pageBlocks: Block[] = getPageBlocks(blocks, page?.key as string);
  const sections: Block[] = getSectionBlocks(blocks);

  return (
    <BlockContext.Provider
      value={{
        blocks,
        initialBlocks,
        pageBlocks,
        sections,
        setBlocks,
        setInitialBlocks
      }}
    >
      {children}
    </BlockContext.Provider>
  );
};
