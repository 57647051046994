import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

interface PreviewContextState {
  preview: boolean;
  setPreview: Dispatch<SetStateAction<boolean>>;
}

export const PreviewContext = createContext<PreviewContextState>({
  preview: false,
  setPreview: () => {}
});

interface PreviewContextProps {
  children: ReactNode;
}

export const PreviewProvider: FunctionComponent<PreviewContextProps> = ({ children }) => {
  const [preview, setPreview] = useState(false);

  return (
    <PreviewContext.Provider value={{ preview, setPreview }}>{children}</PreviewContext.Provider>
  );
};
