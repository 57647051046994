import { useCallback, useEffect, useRef, useState } from 'react';

type Callback<T> = (state: T) => void;
export type SetStateCallback<T> = (state: T, callback?: Callback<T> | undefined) => void;
type Return<T> = [T, SetStateCallback<T>];

/**
 * Run a callback after state is updated.
 *
 * @param initial T
 * @returns Return<T>
 */
export const useStateCallback = <T,>(initial: T): Return<T> => {
  const ref = useRef<Callback<T> | undefined>();

  const [state, setState] = useState<T>(initial);

  const setStateCallback = useCallback((state: T, callback?: Callback<T>) => {
    ref.current = callback;
    setState(state);
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current(state);
      ref.current = undefined;
    }
  }, [state]);

  return [state, setStateCallback];
};
