import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';

import { Block } from '@typings/block';

interface DragAndDropContextState {
  dragged: boolean;
  dragging: boolean;
  reordering: Block | null;
  setDragged: Dispatch<SetStateAction<boolean>>;
  setDragging: Dispatch<SetStateAction<boolean>>;
  setReordering: Dispatch<SetStateAction<Block | null>>;
}

export const DragAndDropContext = createContext<DragAndDropContextState>({
  dragged: false,
  dragging: false,
  reordering: null,
  setDragged: () => {},
  setDragging: () => {},
  setReordering: () => {}
});

interface DragAndDropContextProps {
  children: ReactNode;
}

export const DragAndDropProvider: FunctionComponent<DragAndDropContextProps> = ({ children }) => {
  const [dragged, setDragged] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [reordering, setReordering] = useState<Block | null>(null);

  useEffect(() => {
    if (dragged && !dragging) {
      setTimeout(() => {
        setDragged(false);
      }, 300);
    }
  }, [dragged, dragging]);

  return (
    <DragAndDropContext.Provider
      value={{
        dragged,
        dragging,
        reordering,
        setDragged,
        setDragging,
        setReordering
      }}
    >
      {children}
    </DragAndDropContext.Provider>
  );
};
