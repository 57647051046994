import { useFetch } from '@brandfolder/react';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { DefaultAssetTypes } from '@enums/sections';
import { ApiData, ApiResponseObject } from '@typings/api';
import { Asset } from '@typings/asset';

import { BrandguideContext } from './brandguide';

interface AssetsContextState {
  colorAssets: ApiResponseObject<Asset>[];
  errorColors: Error | undefined;
  fetchColors: () => Promise<void>;
  loadingColors: boolean;
}

export const AssetsContext = createContext<AssetsContextState>({
  colorAssets: [],
  errorColors: undefined,
  fetchColors: () => Promise.resolve(),
  loadingColors: false
});

interface AssetsContextProps {
  children: ReactNode;
}

export const AssetsProvider: FunctionComponent<AssetsContextProps> = ({ children }) => {
  const { brandguide } = useContext(BrandguideContext);
  const [colorAssets, setColorAssets] = useState<ApiResponseObject<Asset>[]>([]);

  const resourceKey = brandguide?.resourceable_key;
  const resourceType = brandguide?.resourceable_type?.toLowerCase();

  const {
    error: errorColors,
    fetch: fetchColors,
    loading: loadingColors,
    response
  } = useFetch<ApiData<Asset>>({
    error: 'Error loading color assets',
    fetchOnMount: false,
    loadingDelay: 300,
    url: `/api/${resourceType}s/${resourceKey}/assets?default_asset_type=${DefaultAssetTypes.Color}`
  });

  useEffect(() => {
    if (response) {
      setColorAssets(response.data);
    }
  }, [response]);

  return (
    <AssetsContext.Provider
      value={{
        colorAssets,
        errorColors,
        fetchColors,
        loadingColors
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};
