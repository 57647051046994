export enum OAuth2Cookies {
  AccessToken = 'bf-brandguide-access-token',
  RefreshToken = 'bf-brandguide-refresh-token'
}

export enum OAuth2Keys {
  Cname = 'cname',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Host = 'host',
  Redirect = 'redirect',
  State = 'state',
  UpdatedAt = 'updatedAt'
}
