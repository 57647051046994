import { AttachmentDto } from '@brandfolder-panel/sdk';
import { Firestore } from '@google-cloud/firestore';
import { deepEqual } from 'fast-equals';
import { nanoid } from 'nanoid';

import { defaultHtml } from '@constants/block';
import {
  BlockDataColorDataKeys,
  BlockDataColorKeys,
  BlockDataDocKeys,
  BlockDataHtmlKeys,
  BlockDataImageKeys,
  BlockDataPdfKeys,
  BlockDataSectionKeys,
  BlockDataVideoKeys,
  BlockKeys,
  BlockTypes,
  PdfLooks,
  VideoMimeTypes
} from '@enums/blocks';
import { FirestoreCollections } from '@enums/firestore-collections';
import { mockEmptyAssetBase, mockEmptyAssetDataColor } from '@mocks/asset';
import { Asset } from '@typings/asset';
import { Attachment } from '@typings/attachment';
import {
  Block,
  BlockColor,
  BlockCreate,
  BlockData,
  BlockDoc,
  BlockDragItem,
  BlockFirestore,
  BlockHtml,
  BlockImage,
  BlockPdf,
  BlockReorderItem,
  BlockSection,
  BlockVideo
} from '@typings/block';
import { getIsAttachmentUrlSmartCdn } from '@utilities/attachment';
import { sanitizer } from '@utilities/sanitize';

/**
 * Get if block `position` value is defined and a valid number.
 *
 * @param position number | undefined
 * @returns boolean
 */
export const getIsPositionDefined = (position?: number | undefined | null): boolean => {
  return position !== undefined && position !== null && !Number.isNaN(position);
};

const getDefaultBlockData = (type: BlockTypes, data?: BlockData): BlockData => {
  if (data) {
    return data;
  }

  switch (type) {
    case BlockTypes.Color: {
      const blockColor: BlockColor = {
        ...mockEmptyAssetBase,
        data: {
          ...mockEmptyAssetDataColor
        },
        key: ''
      };
      return blockColor;
    }
    case BlockTypes.Html: {
      const blockHtml: BlockHtml = {
        html: defaultHtml
      };
      return blockHtml;
    }
    case BlockTypes.Image: {
      const blockImage: BlockImage = {
        cdnUrl: '',
        description: '',
        height: 0,
        key: '',
        name: '',
        thumbnailUrl: '',
        width: 0
      };
      return blockImage;
    }
    case BlockTypes.Section: {
      const blockSection: BlockSection = {
        id: nanoid(),
        name: 'New Section'
      };

      return blockSection;
    }
    default: {
      // BlockTypes.Text
      const blockHtml: BlockHtml = {
        html: 'Text block'
      };
      return blockHtml;
    }
  }
};

/**
 * Create a block object. Returns default data if none is supplied.
 *
 * NOTE: Timestamps will be ISO string for client-side consumption.
 *
 * @param block BlockCreate
 * @returns Block
 */
export const createBlock = (block: BlockCreate): Block => {
  const { data, locale, pageKey, parentKey, position, type } = block;

  return {
    createdAt: new Date().toISOString(),
    data: getDefaultBlockData(type, data),
    deletedAt: null,
    key: nanoid(),
    locale,
    pageKey,
    parentKey,
    position,
    type,
    updatedAt: '' // intentionally blank for new blocks
  };
};

/**
 * Create Firestore image data from Brandfolder attachment and Panel SDK attachment
 *
 * @param dto AttachmentDto
 * @param attachment Attachment
 * @returns BlockImage
 */
export const createBlockImageData = (dto: AttachmentDto, attachment?: Attachment): BlockImage => {
  const cdnUrl = getIsAttachmentUrlSmartCdn(dto.url) ? dto.url : attachment?.cdn_url || '';
  return {
    cdnUrl,
    description: '', // TODO: Not returned from API or Panel SDK
    height: Math.round(dto.dimensions.height), // always use dto for placement options size
    key: dto.id,
    name: dto.name || '',
    thumbnailUrl: dto.thumbnailUrl, // used for local rendering only
    width: Math.round(dto.dimensions.width) // always use dto for placement options size
  };
};

/**
 * Create Firestore video data from Brandfolder attachment and Panel SDK attachment
 *
 * @param dto AttachmentDto
 * @param attachment Attachment
 * @returns BlockVideo
 */
export const createBlockVideoData = (dto: AttachmentDto, attachment?: Attachment): BlockVideo => {
  const cdnUrl = getIsAttachmentUrlSmartCdn(dto.url) ? dto.url : attachment?.cdn_url || '';
  return {
    cdnUrl,
    description: '', // TODO: Not returned from API or Panel SDK
    height: Math.round(dto.dimensions.height), // always use dto for placement options size
    key: dto.id,
    mimeType: dto.mimetype as VideoMimeTypes,
    name: dto.name || '',
    width: Math.round(dto.dimensions.width) // always use dto for placement options size
  };
};

/**
 * Create Firestore document (MS excel, powerpoint, excel) data from Brandfolder attachment and Panel SDK attachment
 *
 * @param dto AttachmentDto
 * @param attachment Attachment
 * @returns BlockDoc
 */
export const createBlockDocData = (dto: AttachmentDto, attachment?: Attachment): BlockDoc => {
  const cdnUrl = getIsAttachmentUrlSmartCdn(dto.url) ? dto.url : attachment?.cdn_url || '';
  return {
    cdnUrl,
    description: '', // TODO: Not returned from API or Panel SDK
    height: Math.round(dto.dimensions.height), // always use dto for placement options size
    key: dto.id,
    name: dto.name || '',
    width: Math.round(dto.dimensions.width) // always use dto for placement options size
  };
};

/**
 * Create Firestore PDF data from Brandfolder attachment and Panel SDK attachment
 *
 * @param dto AttachmentDto
 * @param attachment Attachment
 * @returns BlockPdf
 */
export const createBlockPdfData = (dto: AttachmentDto, attachment?: Attachment): BlockPdf => {
  const cdnUrl = getIsAttachmentUrlSmartCdn(dto.url) ? dto.url : attachment?.cdn_url || '';
  return {
    cdnUrl,
    description: '', // TODO: Not returned from API or Panel SDK
    height: Math.round(dto.dimensions.height), // always use dto for placement options size
    key: dto.id,
    name: dto.name || '',
    width: Math.round(dto.dimensions.width), // always use dto for placement options size
    look: PdfLooks.Pagination
  };
};

/**
 * Insert a block in an array of blocks (via `position`).
 * Also updates position of all blocks.
 *
 * @param block Block
 * @param blocks Block[]
 * @param pageKey string
 * @returns Block[]
 */
export const insertBlock = (block: Block, blocks: Block[], pageKey: string): Block[] => {
  const otherBlocks = blocks.filter((b) => b.pageKey !== pageKey);
  const pageBlocks = blocks.filter((b) => b.pageKey === pageKey);

  return [
    ...otherBlocks,
    ...pageBlocks.slice(0, block.position).map((b, i) => ({ ...b, position: i })),
    { ...block },
    ...pageBlocks.slice(block.position).map((b, i) => ({ ...b, position: block.position + i + 1 }))
  ];
};

/**
 * Update a block object within an array of blocks. NOTE: Doesn't change the position of blocks.
 *
 * TODO: Test the performance of `map`, see if another approach is faster (maybe `findIndex`).
 *
 * @param block Block
 * @param blocks Block[]
 * @returns Block[]
 */
export const updateBlock = (block: Block, blocks: Block[]): Block[] => {
  return blocks.map((b) => (b.key === block.key ? { ...block } : { ...b }));
};

/**
 * Delete a block and any children.
 *
 * @param block Block
 * @param blocks Block[]
 * @param pageKey string
 * @returns Block[]
 */
export const deleteBlock = (block: Block, blocks: Block[], pageKey: string): Block[] => {
  const otherBlocks = blocks.filter((b) => b.pageKey !== pageKey);
  const pageBlocks = blocks.filter((b) => b.pageKey === pageKey);

  const blocksToDelete = pageBlocks.filter((b) => b.key === block.key || b.parentKey === block.key);
  const blocksToUpdate = pageBlocks.filter((b) => b.key !== block.key && b.parentKey !== block.key);

  return [
    ...otherBlocks,
    ...blocksToUpdate.map((b, i) => ({ ...b, position: i })),
    ...blocksToDelete.map((b) => ({ ...b, deletedAt: new Date().toISOString(), position: -1 }))
  ];
};

/**
 * Move section below section.
 *
 * @param block Block
 * @param nextBlock Block[]
 * @param pageBlocks Block[]
 * @returns Block[]
 */
export const moveSectionBelowSection = (
  block: Block,
  nextBlock: Block,
  pageBlocks: Block[]
): Block[] => {
  const position = block.position < -1 ? 0 : block.position;

  const nextSectionBlocks = pageBlocks.filter((b) => b.parentKey === nextBlock.key);
  // eslint-disable-next-line unicorn/prefer-at
  const lastNextSectionBlock = nextSectionBlocks[nextSectionBlocks.length - 1];

  const beforeBlocks = pageBlocks
    .slice(0, lastNextSectionBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({ ...b, position: i }));

  const sectionBlocks = pageBlocks
    .filter((b) => b.parentKey === block.key)
    .map((b, i) => ({
      ...b,
      position: lastNextSectionBlock.position + i + 1,
      updatedAt: new Date().toISOString()
    }));

  // eslint-disable-next-line unicorn/prefer-at
  const lastSectionBlock = sectionBlocks[sectionBlocks.length - 1];

  const afterBlocks = pageBlocks
    .slice(lastNextSectionBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({
      ...b,
      position: lastSectionBlock.position + i + 1,
      updatedAt: new Date().toISOString()
    }));

  return [...beforeBlocks, { ...block, position }, ...sectionBlocks, ...afterBlocks];
};

/**
 * Move section below block.
 *
 * @param block Block
 * @param nextBlock Block[]
 * @param pageBlocks Block[]
 * @returns Block[]
 */
export const moveSectionBelowBlock = (
  block: Block,
  nextBlock: Block,
  pageBlocks: Block[]
): Block[] => {
  const position = block.position < -1 ? 0 : block.position;

  const beforeBlocks = pageBlocks
    .slice(0, nextBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({ ...b, position: i }));

  const sectionBlocks = pageBlocks
    .filter((b) => b.parentKey === block.key)
    .map((b, i) => ({
      ...b,
      position: position + i + 1,
      updatedAt: new Date().toISOString()
    }));

  // eslint-disable-next-line unicorn/prefer-at
  const lastSectionBlock = sectionBlocks[sectionBlocks.length - 1];

  const afterBlocks = pageBlocks
    .slice(nextBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({
      ...b,
      position: lastSectionBlock.position + i + 1,
      updatedAt: new Date().toISOString()
    }));

  return [...beforeBlocks, { ...block, position }, ...sectionBlocks, ...afterBlocks];
};

/**
 * Move section above section.
 *
 * @param block Block
 * @param prevBlock Block[]
 * @param pageBlocks Block[]
 * @returns Block[]
 */
export const moveSectionAboveSection = (
  block: Block,
  prevBlock: Block,
  pageBlocks: Block[]
): Block[] => {
  const position = block.position < -1 ? 0 : block.position;

  const currentSectionBlocks = pageBlocks.filter((b) => b.parentKey === block.key);
  // eslint-disable-next-line unicorn/prefer-at
  const lastCurrentSectionBlock = currentSectionBlocks[currentSectionBlocks.length - 1];
  const currentPrevSectionBlocks = pageBlocks.filter((b) => b.parentKey === prevBlock.key);
  // eslint-disable-next-line unicorn/prefer-at
  const lastCurrentPrevSectionBlock = currentPrevSectionBlocks[currentPrevSectionBlocks.length - 1];

  const beforeBlocks = pageBlocks
    .slice(0, prevBlock.position)
    .filter((b) => b.parentKey !== block.key) // don't think we need to do this
    .map((b, i) => ({ ...b, position: i }));

  const sectionBlocks = pageBlocks
    .filter((b) => b.parentKey === block.key)
    .map((b, i) => ({
      ...b,
      position: position + i + 1,
      updatedAt: new Date().toISOString()
    }));
  // eslint-disable-next-line unicorn/prefer-at
  const lastSectionBlock = sectionBlocks[sectionBlocks.length - 1];

  const prevSectionBlocks = currentPrevSectionBlocks.map((b, i) => ({
    ...b,
    position: lastSectionBlock.position + i + 2, // + 1 for being below the position, + 1 to accommodate the section block
    updatedAt: new Date().toISOString()
  }));
  // eslint-disable-next-line unicorn/prefer-at
  const lastPrevSectionBlock = prevSectionBlocks[prevSectionBlocks.length - 1];

  const betweenBlocks = pageBlocks
    .slice(lastCurrentPrevSectionBlock.position + 1, currentSectionBlocks[0].position)
    .filter((b) => b.parentKey !== block.key) // also don't think we need to do this
    .map((b, i) => ({
      ...b,
      position: lastPrevSectionBlock.position + i + 1
    }));

  const afterBlocks = pageBlocks
    .slice(lastCurrentSectionBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({
      ...b,
      position: lastCurrentSectionBlock.position + i + 1,
      updatedAt: new Date().toISOString()
    }));

  return [
    ...beforeBlocks,
    { ...block, position },
    ...sectionBlocks,
    { ...prevBlock, position: lastSectionBlock.position + 1 },
    ...prevSectionBlocks,
    ...betweenBlocks,
    ...afterBlocks
  ];
};

/**
 * Move section above block.
 *
 * @param block Block
 * @param prevBlock Block[]
 * @param pageBlocks Block[]
 * @returns Block[]
 */
export const moveSectionAboveBlock = (
  block: Block,
  prevBlock: Block,
  pageBlocks: Block[]
): Block[] => {
  const position = block.position < -1 ? 0 : block.position;

  const beforeBlocks = pageBlocks
    .slice(0, prevBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({ ...b, position: i }));

  const sectionBlocks = pageBlocks
    .filter((b) => b.parentKey === block.key)
    .map((b, i) => ({
      ...b,
      position: position + i + 1,
      updatedAt: new Date().toISOString()
    }));
  // eslint-disable-next-line unicorn/prefer-at
  const lastSectionBlock = sectionBlocks[sectionBlocks.length - 1];

  const afterBlocks = pageBlocks
    .slice(prevBlock.position)
    .filter((b) => b.parentKey !== block.key)
    .map((b, i) => ({
      ...b,
      position: lastSectionBlock.position + i + 1,
      updatedAt: new Date().toISOString()
    }));

  return [...beforeBlocks, { ...block, position }, ...sectionBlocks, ...afterBlocks];
};

/**
 * Move a blocks' position
 *
 * @param block Block
 * @param blocks Block[]
 * @returns Block[]
 */
export const moveBlock = (block: Block, blocks: Block[], direction: 'down' | 'up'): Block[] => {
  const otherBlocks = blocks.filter((b) => b.pageKey !== block.pageKey);
  const pageBlocks = blocks.filter((b) => b.pageKey === block.pageKey && b.key !== block.key);
  const position = block.position < -1 ? 0 : block.position;

  if (block.type === BlockTypes.Section) {
    if (direction === 'down') {
      const nextBlock = pageBlocks.find((b) => b.position === position);
      if (nextBlock) {
        return nextBlock.type === BlockTypes.Section
          ? [...moveSectionBelowSection(block, nextBlock, pageBlocks), ...otherBlocks]
          : [...moveSectionBelowBlock(block, nextBlock, pageBlocks), ...otherBlocks];
      } else {
        return [...blocks];
      }
    } else {
      const prevBlock = pageBlocks.find((b) => b.position === position);
      if (prevBlock) {
        return prevBlock.type === BlockTypes.Section
          ? [...moveSectionAboveSection(block, prevBlock, pageBlocks), ...otherBlocks]
          : [...moveSectionAboveBlock(block, prevBlock, pageBlocks), ...otherBlocks];
      } else {
        return [...blocks];
      }
    }
  } else {
    const beforeBlocks = pageBlocks.slice(0, position).map((b, i) => ({ ...b, position: i }));
    const afterBlocks = pageBlocks
      .slice(position)
      .map((b, i) => ({ ...b, position: position + i + 1, updatedAt: new Date().toISOString() }));

    return [...beforeBlocks, { ...block, position }, ...afterBlocks, ...otherBlocks];
  }
};

interface GetMovePositionReturn {
  parentKey: string | null | undefined;
  position: number;
}

/**
 * Get the new position for moving a block down.
 *
 * Should never be called on a section heading block.
 *
 * @param block Block
 * @param pageBlocks Block[]
 * @returns GetMovePositionReturn
 */
export const getMoveDownPosition = (block: Block, pageBlocks: Block[]): GetMovePositionReturn => {
  const { parentKey, position, type } = block;

  if (type === BlockTypes.Section) {
    // can't put a section inside a section, so just filter out all section children
    const nextBlock = pageBlocks.filter((b) => !b.parentKey).find((b) => b.position > position);

    if (nextBlock) {
      // move below block
      return {
        parentKey: null,
        position: nextBlock.position
      };
    }

    // can't go any further down, stay in place
    return {
      parentKey,
      position
    };
  } else {
    const nextBlock = pageBlocks.find((b) => b.position === position + 1);

    if (nextBlock) {
      // move a block inside a section block
      if (nextBlock.type === BlockTypes.Section && !parentKey) {
        return {
          parentKey: nextBlock.key || null,
          position: nextBlock.position + 1 // skip the section heading block
        };
      }

      // move a block outside of a section block before the next block
      if (parentKey && !nextBlock.parentKey) {
        return {
          parentKey: null,
          position: position
        };
      }

      // or just below the next non-section block
      return {
        parentKey: nextBlock.parentKey || null,
        position: nextBlock.position
      };
    }

    // move a block outside of a section block
    if (parentKey) {
      return {
        parentKey: null,
        position
      };
    }

    // can't go any further down, stay in place
    return {
      parentKey: parentKey || null,
      position
    };
  }
};

/**
 * Get the new position for moving a block up.
 *
 * Should never be called on a section heading block.
 *
 * @param block Block
 * @param pageBlocks Block[]
 * @returns GetMovePositionReturn
 */
export const getMoveUpPosition = (block: Block, pageBlocks: Block[]): GetMovePositionReturn => {
  const { parentKey, position, type } = block;

  if (type === BlockTypes.Section) {
    // can't put a section inside a section, so just filter out all section children
    const prevBlock = pageBlocks
      .filter((b) => !b.parentKey)
      .reverse()
      .find((b) => b.position < position);

    if (prevBlock) {
      // move above section child block
      return {
        parentKey: null,
        position: prevBlock.position
      };
    }

    // can't go any further down, stay in place
    return {
      parentKey,
      position
    };
  } else {
    const prevBlock = pageBlocks.find((b) => b.position === position - 1);
    const prevPrevBlock = pageBlocks.find((b) => b.position === position - 2);

    // move out of section block
    if (
      parentKey &&
      prevBlock?.type === BlockTypes.Text &&
      prevPrevBlock?.type === BlockTypes.Section
    ) {
      return {
        parentKey: null,
        position: prevBlock.position === 0 ? 0 : prevBlock.position - 1
      };
    }

    // move in to section block as last block
    if (!parentKey && prevBlock?.parentKey && prevBlock.type !== BlockTypes.Section) {
      return {
        parentKey: prevBlock.parentKey || null,
        position // stays the same in this scenario
      };
    }

    if (prevBlock) {
      return {
        parentKey: prevBlock.parentKey || null,
        position: prevBlock.position
      };
    }

    // can't go any further up, stay in place
    return {
      parentKey: parentKey || null,
      position
    };
  }
};

/**
 * Detect whether a block is the last one on the canvas for a page
 *
 * @param block Block
 * @param pageBlocks Block[]
 * @returns boolean
 */
export const getIsLastBlock = (block: Block, pageBlocks: Block[]): boolean => {
  const { key, position, type } = block;

  if (position === pageBlocks.length - 1) return true;

  if (type === BlockTypes.Section) {
    const remainingBlocks = pageBlocks.slice(position + 1);
    return remainingBlocks.filter((b) => b.parentKey === key).length === remainingBlocks.length;
  }

  return false;
};

/**
 * Detect whether there is only one block on the canvas for a page
 *
 * @param pageBlocks Block[]
 * @returns boolean
 */
export const getIsOnlyBlock = (pageBlocks: Block[]): boolean => {
  if (pageBlocks.length === 1) return true;
  const section = pageBlocks.find((b) => b.type === BlockTypes.Section);
  return (
    !!section &&
    pageBlocks.filter((b) => b.parentKey === section.key).length === pageBlocks.length - 1
  );
};

/**
 * Get whether block data is color
 * @param data BlockData
 * @returns data is BlockColor
 */
export const getBlockDataIsColor = (data: BlockData): data is BlockColor => {
  return (
    deepEqual(Object.keys(data).sort(), Object.values(BlockDataColorKeys).sort()) &&
    deepEqual(
      Object.keys((data as BlockColor).data).sort(),
      Object.values(BlockDataColorDataKeys).sort()
    )
  );
};

/**
 * Get whether block data is HTML
 * @param data BlockData
 * @returns data is BlockHtml
 */
export const getBlockDataIsHtml = (data: BlockData): data is BlockHtml => {
  return deepEqual(Object.keys(data), Object.values(BlockDataHtmlKeys));
};

/**
 * Get whether block data is Excel, PowerPoint, or Word
 * @param data BlockData
 * @returns data is BlockDoc
 */
export const getBlockDataIsDoc = (data: BlockData | undefined): data is BlockDoc => {
  if (!data) return false;
  return deepEqual(Object.keys(data).sort(), Object.values(BlockDataDocKeys).sort());
};

/**
 * Get whether block data is image
 * @param data BlockData
 * @returns data is BlockImage
 */
export const getBlockDataIsImage = (data: BlockData): data is BlockImage => {
  return deepEqual(Object.keys(data).sort(), Object.values(BlockDataImageKeys).sort());
};

/**
 * Get whether block data is PDF
 * @param data BlockData
 * @returns data is BlockPdf
 */
export const getBlockDataIsPdf = (data: BlockData | undefined): data is BlockPdf => {
  if (!data) return false;
  return deepEqual(Object.keys(data).sort(), Object.values(BlockDataPdfKeys).sort());
};

/**
 * Get whether block data is section
 * @param data BlockData
 * @returns data is BlockScript
 */
export const getBlockDataIsSection = (data: BlockData | undefined): data is BlockSection => {
  if (!data) return false;
  return deepEqual(Object.keys(data).sort(), Object.values(BlockDataSectionKeys).sort());
};

/**
 * Get whether block data is video
 * @param data BlockData
 * @returns data is BlockVideo
 */
export const getBlockDataIsVideo = (data: BlockData): data is BlockVideo => {
  return deepEqual(Object.keys(data).sort(), Object.values(BlockDataVideoKeys).sort());
};

/**
 * Create a block via drag and drop. Ensures `key` is created.
 *
 * @param options BlockDragItem
 * @returns BlockDragItem
 */
export const createBlockDragItem = (options: BlockDragItem): BlockDragItem => {
  return {
    ...options,
    key: nanoid()
  };
};

/**
 * Get whether drag and drop data is BlockReorderItem
 *
 * @param data BlockReorderItem
 * @returns data is BlockReorderItem
 */
export const getIsBlockReorderItem = (
  data: BlockDragItem | BlockReorderItem
): data is BlockReorderItem => {
  return Object.keys(data).length === 1 && Object.prototype.hasOwnProperty.call(data, 'key');
};

/**
 * Check to see if there is a duplicate section id for a given page.
 * Example: `#color` cannot be added twice on `/logos#color` page slug.
 *
 * Different pages are allowed to save the same id.
 * For example, `/logos#color` and `/icons#color` is allowed.
 *
 * @param id string
 * @param key string
 * @param pageKey string
 * @param sections Block[]
 * @returns boolean
 */
export const getSectionIsDuplicate = (
  id: string,
  key: string,
  pageKey: string,
  sections: Block[]
): boolean => {
  return sections.some(
    (section) =>
      section.key !== key &&
      section.pageKey === pageKey &&
      getBlockDataIsSection(section.data) &&
      section.data.id === id
  );
};

/**
 * Corresponds to #main, #menu, #nav, #top
 */
export const reservedSectionNames = new Set(['Main', 'Menu', 'Nav', 'Top']);

/**
 * Check to see if a section name is reserved.

 * @param name string
 * @returns boolean
 */
export const getSectionIsReserved = (name: string): boolean => {
  if (!name) return false;
  return reservedSectionNames.has(name);
};

/**
 * Get blocks that belong to a page.
 *
 * @param blocks Block[]
 * @param pageKey string
 * @returns Block[]
 */
export const getPageBlocks = (blocks: Block[], pageKey: string): Block[] => {
  return blocks.filter((block) => block.pageKey === pageKey);
};

/**
 * Sanitize block data
 *
 * @param data BlockData
 * @returns BlockData
 */
export const sanitizeBlockData = <T extends BlockData>(data?: T | undefined): T | undefined => {
  if (!data) return data;

  if (getBlockDataIsColor(data)) {
    const { description, name } = data;
    return {
      ...data,
      ...(typeof description === 'string' && { description: sanitizer(description) }),
      ...(typeof name === 'string' && { name: sanitizer(name) })
    };
  }
  if (getBlockDataIsHtml(data)) {
    const { html } = data;
    return {
      ...data,
      ...(typeof html === 'string' && { html: sanitizer(html) })
    };
  }
  if (getBlockDataIsSection(data)) {
    const { name } = data;
    return {
      ...data,
      ...(typeof name === 'string' && { name: sanitizer(name) })
    };
  }

  return data;
};

/**
 * Filter a list of blocks to only section blocks.
 *
 * @param blocks Block[]
 * @returns Block[]
 */
export const getSectionBlocks = (blocks: Block[]): Block[] => {
  return blocks.filter((block) => block.type === BlockTypes.Section);
};

/**
 * Create a block PATCH promise for updating a block
 * @param block Block
 * @returns Promise<Response>
 */
export const createBlockPatchPromise = (block: Block, brandguideKey: string) => {
  return fetch(`/api/blocks/${block.key}`, {
    body: JSON.stringify({
      data: {
        attributes: block
      }
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-brandguide-key': brandguideKey
    },
    method: 'PATCH'
  });
};

/**
 * Determine whether a boulder color asset has changed and no longer matches a color block's data
 * @param asset Asset
 * @param block Block
 * @returns boolean
 */
export const getHasColorBlockChanged = (asset: Asset, block: Block): boolean => {
  const { data: assetData } = asset;
  const { data: blockData } = block;

  if (!assetData || !getBlockDataIsColor(blockData)) return false;

  const { description: assetDescription, name: assetName } = asset;
  const { data, description, name } = blockData;

  const changedDescription = assetDescription !== null && description !== assetDescription;
  if (changedDescription) return true;

  const changedName = name !== assetName;
  if (changedName) return true;

  const changedData = !deepEqual(assetData, data);
  return changedData;
};

/**
 * Fetch a pages' blocks from Firestore
 *
 * @param db Firestore
 * @param pageKey string
 * @returns Promise<Block[]>
 */
export const fetchPageBlocks = async (db: Firestore, pageKey: string): Promise<Block[]> => {
  const blocks: Block[] = [];

  try {
    const blocksRef = db
      .collection(FirestoreCollections.Blocks)
      .where(BlockKeys.DeletedAt, '==', null)
      .where(BlockKeys.PageKey, '==', pageKey)
      .orderBy(BlockKeys.Position, 'asc'); // MUST be ascending (changing will break the entire app)

    const blocksSnapshot = await blocksRef.get();

    if (!blocksSnapshot.empty) {
      blocksSnapshot.forEach((doc) => {
        blocks.push({
          ...(doc.data() as BlockFirestore),
          key: doc.id
        });
      });
    }

    return blocks;
  } catch {
    return blocks;
  }
};

/**
 * Sorts an array of blocks by position, returning a new array.
 * @param blocks Block[]
 * @returns Block[]
 */
export const sortBlocksByPosition = (blocks: Block[]): Block[] => {
  return [...blocks.sort((a, b) => a.position - b.position)];
};
