import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';

import { BrandguideWhitelabel, WhitelabelScope } from '@typings/whitelabel';
import { brandguideWhitelabel } from '@utilities/whitelabel';

import { ConfigContext } from './config';

interface WhitelabelContextState {
  setWhitelabelScope: Dispatch<SetStateAction<WhitelabelScope>>;
  whitelabel: BrandguideWhitelabel;
  whitelabelScope: WhitelabelScope;
}

export const WhitelabelContext = createContext<WhitelabelContextState>({
  setWhitelabelScope: () => {},
  whitelabel: {
    ...brandguideWhitelabel(WhitelabelScope.Brandfolder),
    BOULDER_URL: '',
    BRANDGUIDE_URL: ''
  },
  whitelabelScope: WhitelabelScope.Brandfolder
});

interface WhitelabelContextProps {
  children: ReactNode;
}

export const WhitelabelProvider: FunctionComponent<WhitelabelContextProps> = ({ children }) => {
  const { publicRuntimeConfig } = useContext(ConfigContext);

  const [whitelabel, setWhitelabel] = useState<BrandguideWhitelabel>({
    ...brandguideWhitelabel(WhitelabelScope.Brandfolder),
    BRANDGUIDE_URL: publicRuntimeConfig.NEXTAUTH_URL,
    BOULDER_URL: publicRuntimeConfig.BRANDFOLDER_BOULDER_URL
  });
  const [whitelabelScope, setWhitelabelScope] = useState(WhitelabelScope.Brandfolder);

  useEffect(() => {
    setWhitelabel({
      ...brandguideWhitelabel(whitelabelScope),
      BRANDGUIDE_URL:
        whitelabelScope === WhitelabelScope.Brandfolder
          ? publicRuntimeConfig.NEXTAUTH_URL
          : publicRuntimeConfig.GETTY_BRANDGUIDE_HOST,
      BOULDER_URL:
        whitelabelScope === WhitelabelScope.Brandfolder
          ? publicRuntimeConfig.BRANDFOLDER_BOULDER_URL
          : publicRuntimeConfig.GETTY_BOULDER_URL
    });
  }, [
    whitelabelScope,
    publicRuntimeConfig.BRANDFOLDER_BOULDER_URL,
    publicRuntimeConfig.GETTY_BOULDER_URL,
    publicRuntimeConfig.GETTY_BRANDGUIDE_HOST,
    publicRuntimeConfig.NEXTAUTH_URL
  ]);

  return (
    <WhitelabelContext.Provider value={{ setWhitelabelScope, whitelabel, whitelabelScope }}>
      {children}
    </WhitelabelContext.Provider>
  );
};
