import { datadogRum } from '@datadog/browser-rum';
import pino from 'pino';

// ***copied from pino source code
interface LogEvent {
  level: {
    label: string;
    value: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: any[];
  ts: number;
}

interface ErrorEvent {
  msg: string;
  stack: string;
  type: string;
}

class DatadogError extends Error {
  constructor(errorEvent: ErrorEvent) {
    super(errorEvent.msg);
    this.stack = errorEvent.stack;
    this.name = errorEvent.type;
  }
}

const sendEvent = (logEvent: LogEvent) => {
  const message = logEvent.messages[0];
  let error = null;

  if (message?.stack) {
    // a pino logged error needs some transforming
    error = new DatadogError(message);
  } else if (typeof message === 'string') {
    // a pino logged string
    error = new Error(message);
  } else {
    // a pino logged object or anything else
    error = new Error(JSON.stringify(message));
  }

  datadogRum.addError(error);
};

const logger = pino({
  browser: {
    transmit: {
      level: 'warn',
      send: (_level, logEvent: LogEvent) => {
        sendEvent(logEvent);
      }
    },
    serialize: true
  },
  formatters: {
    level: (label) => {
      return {
        level: label
      };
    }
  }
});

export { logger as default, sendEvent, DatadogError };
